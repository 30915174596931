@import "../../../../../../Global/Styles/global";

.slider-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  min-height: 50%;
  width: 70%;
  z-index: 4;

  .header-image {
    width: 30%;
    object-fit: contain;
    height: auto;
    margin: 0 50px;
    min-width: 300px;
  }

  .slide-title {
    font-size: 5em;
    max-width:  50%;
    font-family: nordin;
    color: $slider-title-color ;
  }
}

@media only screen and (max-width: 1024px) {
  .slider-header {
    .header-image {
      margin: 0;
    }

    .slide-title{
      width: 100%;
      max-width: 100%;
      text-align: center;
      font-size: 3em;
    }
  }
}