.main-app-container {
  min-height: 100vh;
  width: 100%;

  .image-slider-container {
    width: 100%;
    height: 70vh;
  }

  .gallery-container {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: nowrap;
    padding: 40px;
    background-color: #FF7B33;

    .multiple-images {
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;
      align-content: center;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .main-app-container {
    .gallery-container {
      flex-wrap: wrap;

      .alone-image {
        display: flex;
        justify-content: center;
        min-width: 300px;
        width: 100%;
      }
    }
  }
}