@import "../../../../../../Global/Styles/global";

section {

  .slides {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    position: relative;
  }

  .background-blur{
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba(0,0,0,0.25);
    backdrop-filter: blur(1px);
    z-index: 0;
  }

  .first{
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 45%;
  }

  .second{
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 47%;
  }

  .third{
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 28%;
  }

  .fourth{
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 48%;
  }

  .sub-titles-container {
    width: 100%;
    z-index: 4;

    h5 {
      width: 100%;
      text-align: center;
      font-family: nordin;
      color: $slider-title-color;
      font-size: 2em;
    }
    h3 {
      width: 100%;
      text-align: center;
      font-family: nordin;
      color: $slider-title-color;
      font-size: 3em;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .slides {
    .sub-titles-container{
      display: none;
    }
  }
}