
@import "src/Global/Styles/global";

.back-button-container{
  display: flex;
  width: 100%;
  height: 50px;
  margin: 10px 0;
  justify-content: center;

  .back-button{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%;
    min-width: 200px;
    padding: 0;
    background-color: $main-color-titles;
    text-decoration: none;
    color: $button-text-color;
    border-radius: 50px;
    font-family: bahnschrift;
    font-size: 1.5em;
  }
}

@media only screen and (max-width: 1024px) {
  #root {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}