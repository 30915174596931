@import "../../../../../../Global/Styles/global";


.circle-info-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 20vw;
  height: 20vw;
  min-width: 340px;
  min-height: 340px;
  border-radius: 100%;
  background-color: $main-color-titles;
  margin: 30px 0;

  .icon-container {
    width: 100%;
    display: flex;
    justify-content: center;

    .icon {
      width: 70px;
      height: 70px;
      -webkit-filter: invert(100%);
    }
  }

  .paragraph {
    width: 70%;
    color: $button-text-color;
    font-family: bahnschrift;
    text-align: center;
    font-size: 1em;
    font-weight: normal;
  }
}

@media only screen and (max-width: 1024px) {
  .circle-info-container {
    .paragraph {
      font-size: 1em;
    }
  }
}