@import "../../../../../Global/Styles/global";

.what-we-offer-section-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .section-title {
    margin: 50px 0;
    width: 85%;
    color: $main-color-titles;
    font-size: 3em;
  }

  .dots-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 80%;
  }

  .section-paragraph {
    color: $main-color-titles;
    font-family: bahnschrift;
    font-weight: bold;
    font-size: 1.5em;
    text-align: center;
    margin: 70px 0;
    padding: 0 15%;
  }
}

@media only screen and (max-width: 1024px) {
  .what-we-offer-section-container{
    .section-paragraph{
      width: 100%;
      padding: 0 40px;
      text-align: justify;
    }
  }
}