@import "../../Global/Styles/global";

.footer {
  display: flex;
  background-color: $footer-background-color;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-evenly;

  .logo-container {
    display: flex;
    width: 20%;
    min-width: 300px;
    padding: 0 0 0 150px;

    .logo{
      width: 50%;
      padding: 20px;
    }
  }

  .social-media-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 120px;
    max-height: 140px;
    margin: 30px 0;

    h4 {
      margin: 20px 0;
      width: 100%;
      color: $button-text-color;
      text-align: center;
      font-family: Bahnschrift;
    }
  }

  .contact-us {
    display: flex;
    justify-content: space-between;
    width: 180px;
    max-height: 140px;
    align-items: center;
    margin: 30px 0;

    div {
      .title {
        font-weight: bold;
      }
      p {
        font-size: 1.3em;
        color: $button-text-color;
        font-family: Bahnschrift;
      }
    }

  }

  .mail-container{
    display: flex;
    justify-content: space-between;
    width: 300px;
    max-height: 140px;
    margin: 30px 0;


    div {
      .title {
        font-weight: bold;
      }
      p {
        font-size: 1.3em;
        color: $button-text-color;
        font-family: Bahnschrift;
      }
    }
  }

  .icon {
    width: 40px;
    height: 40px;
    -webkit-filter: invert(100%);
  }
}

@media only screen and (max-width: 1024px) {
  .footer{
    .logo-container, .social-media-container, .mail-container {
      width: 100%;
      padding: 0;
      min-width: 200px;
      justify-content: space-evenly;
    }

    .contact-us{
      width: 51%;
    }
  }
}