.gallery-image{
  width: 10vw;
  height: 10vw;
  object-fit: cover;
  object-position: 50% 50%;
  margin: 20px 5px;
  border-radius: 30px;
  min-width: 300px;
  min-height: 300px;
}

.first {
  height: 100%;
  width: 30vw;
}

@media only screen and (max-width: 1024px) {
  .first {
    display: none;
  }
}