.arrows {
  color: white;

  .next, .prev {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    cursor: pointer;
    z-index: 10;
    position: absolute;
    top: 40%;
    padding: 1rem;
    font-size: 30px;
    font-weight: bold;
    border-radius: 100%;
    margin: 0 5em;

    &:hover {
      color: black;
      background-color: rgba(255, 255, 255, 0.6);
      transition: all 0.5s ease-in;
    }
  }

  .next {
    right: 0;
    border-radius: 100%;
    transform: rotate(180deg);
  }
}

@media only screen and (max-width: 1024px) {
  .arrows {
    .next, .prev {
      margin: 0;
    }
  }
}