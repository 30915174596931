$height: 100%;
$width: 100%;

.slider-container {
  height: $height;
  width: $width;
  margin: auto;
  overflow: hidden;

  section {
    height: 100%;
  }


}