@import "../../../../../Global/Styles/global";

.rooms-container{
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  .room-card-section-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background-color: $background-room-color-container;

    .sub-section-title{
      margin: 50px 0 0 0;
      width: 85%;
      color: $main-color-titles;
      font-size: 3em;
    }

    .room-card-container{
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      width: 85%;
    }
  }
}