@import "../../../../../../Global/Styles/global";

.section-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 30px 0;
  min-height: 10vh;
  width: 100%;

  .paragraph {
    color: $main-color-titles;
    font-family: bahnschrift;
    font-weight: bold;
    font-size: 1.5em;
    text-align: center;
    margin: 20px;
    padding: 0 15%;
  }
}

@media only screen and (max-width: 1024px) {
  .section-container{
    .paragraph{
      width: 100%;
      padding: 0;
      text-align: justify;
    }
  }
}