body {
  margin: 0;
  min-height: 100vh;
  max-width: 100%;
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  margin: 0;
  min-height: 100vh;
  max-width: 100%;
  overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6, p, a{
  margin: 0;
  padding: 0;
  font-size: 1em;
  text-decoration: none;
}

@font-face {
  font-family: 'Nordin';
  src: local('nordin'), url(./assets/fonts/Nordin/NordinRegular.ttf) format('ttf');
}


@font-face {
  font-family: 'Nordin';
  src: local('nordin-outline'), url(./assets/fonts/Nordin/NordinOutline.ttf) format('ttf');
}


@font-face {
  font-family: 'Nordin';
  src: local('nordin-rough'), url(./assets/fonts/Nordin/NordinRough.ttf) format('ttf');
}

@font-face {
  font-family: 'bahnschrift';
  src: local('bahnschrift'), url("./assets/fonts/Bahnschrift/bahnschrift.ttf") format('ttf');
}

@media only screen and (max-width: 1024px) {
  h1, h2, h3, h4, h5, h6, p, a{
    text-align: center;
  }
}