.all-dots {
  width: 100%;
  height: 30px;
  position: absolute;
  display: flex;
  top: 65%;
  justify-content: center;
  align-items: center;
  z-index: 20;

  .dot {
    height: 1rem;
    width: 1rem;
    margin: 0 3px;
    background-color: rgba(0, 0, 0, 0.3);

    border-radius: 50%;
    display: flex;
  }

  .active {
    background-color: rgba(255, 255, 255, 0.5);
  }
}

@media only screen and (max-width: 1024px) {
  .all-dots {
    display: none;
  }
}