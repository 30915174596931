@import "../../../../../../Global/Styles/global";


.card {
  min-width: 340px;
  width: 25%;
  min-height: 520px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  -webkit-box-shadow: 10px 10px 31px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 10px 10px 31px 0px rgba(0,0,0,0.75);
  box-shadow: 10px 10px 31px 0px rgba(0,0,0,0.75);
  margin: 30px;
  border-radius: 20px;
  background-color: $background-room-color;

  .image-container {
    height: 60%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 60%;
    width: 100%;
  }

  .card-title {
    width: 100%;
    margin: 10px 0 10px 20px;
    font-size: 1.5em;
    font-family: bahnschrift;
    font-weight: bold;
    color: $main-color-titles;
  }

  .card-description {
    width: 100%;
    margin: 5px 20px 20px 20px;
    font-size: 1em;
    font-family: bahnschrift;
    text-align: justify;
    color: $main-paragraph-color;
  }

  .see-room-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $redirect-background-color;
    text-decoration: none;
    color: $button-text-color;
    padding: 5px 40px ;
    margin: 0 0 30px 0;
    border-radius: 50px;
  }
}

@media only screen and (max-width: 1024px) {
  .card {
    margin: 20px 0;

    .card-description{
      margin: 10px 0;
      padding: 0 20px;
    }
  }
}